<script>
  import { slide } from "svelte/transition";
  import Cookies from "js-cookie";

  export let bannerOpen = false;
  let detailsOpen = false;

  // config variblen
  export let consentText =
    "Wir verwenden Cookies, um Inhalte<b> und</b> Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben. Sie geben Einwilligung zu unseren Cookies, wenn Sie unsere Webseite weiterhin nutzen.";
  export let detailsText = "Lorem ipsum dolor sit amet...";

  export let acceptAllLabel = "Alle akzeptieren";
  export let acceptSelectionLabel = "Nur Auswahl akzeptieren";

  export let detailsLabel = "Details zeigen";
  export let privacyLabel = "Datenschutz";
  export let privacyUrl = "";
  export let imprintLabel = "Impressum";
  export let imprintUrl = "";

  export let bgColor = "#009fa1";
  export let textColor = "#ffffff";
  export let cookieDomain = "";
  export let proofUrl = "";

  export let consentCategories = [
    {
      name: "necessary",
      show: true,
      disabled: true,
      label: "Notwendig",
      default: true
    },
    {
      name: "preferences",
      show: true,
      disabled: false,
      label: "Präferenzen",
      default: false
    },
    {
      name: "analytics",
      show: true,
      disabled: false,
      label: "Statistiken",
      default: false
    },
    {
      name: "marketing",
      show: true,
      disabled: false,
      label: "Marketing",
      default: false
    }
  ];

  if(cookieDomain == "")
  {
    cookieDomain = location.hostname;

    let hostname = document.location.hostname.split('.');

    if(hostname.length == 2)
    {
      cookieDomain = "."+hostname[0]+"."+hostname[1];
    }
    else if(hostname.length == 3)
    {
      cookieDomain = "."+hostname[hostname.length-2]+"."+hostname[hostname.length-1];
    }
  }

  //console.log(cookieDomain);

  let cookieConsent = Cookies.getJSON("cookie_consent", {domain: cookieDomain});

  if (typeof cookieConsent == "undefined") {
    cookieConsent = Cookies.getJSON("cookie_consent");
  }

  if (typeof cookieConsent == "undefined") {
    cookieConsent = {};
    consentCategories.map(category => {
      cookieConsent[category.name] = category.default;
    });
    bannerOpen = true;
  }

  const acceptAll = () => {
    consentCategories.map(category => {
      cookieConsent[category.name] = category.show ? true : false;
    });

    cookieConsent.hostname = location.hostname;
    cookieConsent.timestamp = Date.now();
    
    if (proofUrl !== "") {
      getProof();
    } else {
      saveCookie();
    }

    bannerOpen = false;
  };

  const acceptSelection = e => {
    e.preventDefault();

    consentCategories.map(category => {
      cookieConsent[category.name] = category.show
        ? cookieConsent[category.name]
        : false;
    });

    cookieConsent.hostname = location.hostname;
    cookieConsent.timestamp = Date.now();

    if (proofUrl !== "") {
      getProof();
    } else {
      saveCookie();
    }

    bannerOpen = false;
  };

  const getProof = () => {
    fetch(proofUrl, {
      method: "POST",
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(cookieConsent)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      .then(data => {
        cookieConsent = data;
        saveCookie();
      })
      .catch(error => {
        console.error("Error");
      });
  };

  const saveCookie = () => {

    Cookies.remove("cookie_consent", {domain: ""}); // Remove legacy cookie

    let domain = cookieDomain;
    if(domain == "" && location.hostname.match(/\./g).length == 1)
    {
      domain = "."+domain;
    }

    Cookies.set("cookie_consent", cookieConsent, {
      expires: cookieConsent["preferences"] ? 365 : null,
      domain: cookieDomain
    });

    consentCategories.map(category => {
      if (typeof category.callback !== "undefined") {
        category.callback(cookieConsent[category.name]);
      }
    });
  };

  const toggleDetails = e => {
    e.preventDefault();
    detailsOpen = !detailsOpen;
  };
</script>

<style>
  .cookiebanner {
    z-index: 9999;

    position: fixed;
    bottom: 0;
    width: 100%;

    font-size: 12px;
    line-height: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
  }

  .cookiebanner-content {
    padding: 15px;
    max-height: calc(100vh);
    overflow-y: auto;
  }

  .cookiebanner-cookietext {
    margin-bottom: 10px;
  }

  .cookiebanner-detailtext {
    padding: 10px;
    background-color: #fff;
    color: #202020;
    border-radius: 4px;
  }

  .cookiebanner-buttonbox {
    display: flex;
  }
  .cookiebanner-buttonbox > div {
    flex: 0 0 50%;
  }

  button {
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    width: 100%;
  }

  .acceptselection {
    text-align: center;
    flex: 0 0 50%;
  }

  a {
    text-decoration: underline;
  }

  a.checkbox {
    text-decoration: none;
  }

  a.checkbox i {
    font-style: normal;
    font-size: 16px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  li {
    width: 120px;
    cursor: pointer;
  }

  /* style der eintritt solange größer als -> (min-width 700 wirk erst bei 630!!) */
  @media only screen and (min-width: 800px) {
    .cookiebanner-content {
      display: flex;
      justify-content: space-between;
    }

    .cookiebanner-maincontent {
      flex: 1 0px;
    }

    .cookiebanner-buttonbox {
      display: block;
      min-width: 200px;
      margin-left: 10px;
    }
    .cookiebanner-buttonbox button {
      margin-bottom: 10px;
    }

    ul {
      margin-bottom: 0px;
    }
  }
</style>

<svelte:options accessors={true} />
{#if bannerOpen}
  <div
    class="cookiebanner"
    style="background-color: {bgColor}; color: {textColor}"
    transition:slide>
    <div class="cookiebanner-content">
      <div class="cookiebanner-maincontent">

        <div class="cookiebanner-cookietext">
          {#if detailsOpen}
            <div class="cookiebanner-detailtext">
              {@html detailsText}
            </div>
          {:else}
            <div>
              {@html consentText}
            </div>
          {/if}
        </div>

        <ul>

          {#each consentCategories as category, i}
            {#if category.show}
              <li>
                <a
                  class="checkbox"
                  href=""
                  style="color: {textColor}; {category.disabled ? ' cursor: not-allowed;' : ''}"
                  on:click={e => {
                    e.preventDefault();
                    if (!category.disabled) {
                      cookieConsent[category.name] = !cookieConsent[category.name];
                    }
                  }}>
                  <i>{cookieConsent[category.name] ? '✓' : '×'}</i>
                  <span>{category.label}</span>
                </a>
              </li>
            {/if}
          {/each}

          <li>
            <a href="" style="color: {textColor}" on:click={toggleDetails}>
              {detailsLabel}
            </a>
          </li>

          <li>
            <a href={privacyUrl} style="color: {textColor}">{privacyLabel}</a>
          </li>
          <li>
            <a href={imprintUrl} style="color: {textColor}">{imprintLabel}</a>
          </li>
        </ul>
      </div>

      <div class="cookiebanner-buttonbox">
        <div>
          <button
            style="background-color: {textColor}; color: {bgColor}; cursor:
            pointer"
            on:click={acceptAll}>
            {acceptAllLabel}
          </button>
        </div>
        <div class="acceptselection">
          <a href="" style="color: {textColor}" on:click={acceptSelection}>
            {acceptSelectionLabel}
          </a>
        </div>

      </div>
    </div>
  </div>
{/if}
