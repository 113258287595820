import App from "./App.svelte";

let app; 

function attach(target, config = {}) {
  app = new App({
    target,
    props: config
  });
}

window.CookieConsent = window.CookieConsent || {};
window.CookieConsent.attach = attach;

window.CookieConsent.open = function() {
  app.bannerOpen = true;
}
